<template>
    <article
        :id="id"
        class="list-service-categories-container"
    >
        <div class="list-service-categories">
            <WatsonAtomDynamicText
                v-if="title"
                :text="title"
                :typography="typography.title"
            />

            <div class="list-service-categories__columns">
                <div
                    v-for="(column, columnIndex) in columns"
                    :key="columnIndex"
                    class="list-service-categories__column"
                >
                    <WatsonAtomServiceCard
                        v-for="(item, itemIndex) in column"
                        :key="itemIndex"
                        :content="item"
                        :style="{}"
                        :hide-description="hideCardDescription"
                        :item-index="itemIndex"
                        :column-index="columnIndex"
                        :min-height="rowHeights[itemIndex]"
                        @update-card-size="height => updateCardSize(height, itemIndex)"
                    />
                </div>
            </div>
            <Link
                v-if="linkUrl"
                :href="linkUrl"
                target="_self"
                class="list-service-categories__link"
            >
                {{ formattedLinkText }}
            </Link>
        </div>
    </article>
</template>

<script setup>
import { computed, ref } from 'vue';
import WatsonAtomServiceCard from '../service-card/watson-theme-service-card.vue';
import WatsonAtomDynamicText from '@atoms/dynamic-text/watson-atom-dynamic-text.vue';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    id: {
        type: String,
        required: false,
        default: '',
    },
    items: {
        type: Object,
        required: true,
    },
    hideCardDescription: {
        type: Boolean,
        required: false,
        default: false,
    },
    linkText: {
        type: String,
        required: false,
        default: '',
    },
    linkUrl: {
        type: String,
        required: false,
        default: '',
    },
    style: {
        type: Object,
        required: false,
        default: () => ({
            background: '',
            titleColor: '',
        }),
    },
    typography: {
        type: Object,
        required: false,
        default: () => ({
            title: {
                tag: 'h2',
                visual: 'heading-2-bold',
            },
        }),
    },
});

const rowHeights = ref([]);

const gridColumns = computed(() => {
    const itemCount = Object.keys(props.items).length;
    if (itemCount <= 2) return 1;
    if (itemCount <= 4) return 2;
    return 3;
});

const formattedLinkText = computed(() => props.linkText.replace('{services}', Object.keys(props.items).length));

const columns = computed(() => {
    const result = Array.from({ length: gridColumns.value }, () => []);
    const itemsArray = Object.values(props.items);

    itemsArray.forEach((item, index) => {
        const columnIndex = index % gridColumns.value;
        result[columnIndex].push(item);
    });

    return result;
});

const updateCardSize = (height, itemIndex) => {
    if (height < rowHeights.value[itemIndex]) return;
    rowHeights.value[itemIndex] = height;
};
</script>

<style lang="scss">
@use 'watson-theme-list-service-categories';
</style>

<style lang="scss" scoped>
@layer theme {
    .list-service-categories {
        --background: v-bind('props.style.background');
        --title-color: v-bind('props.style.titleColor');
        --column-count: v-bind(gridColumns);
    }
}
</style>
