<template>
    <article
        ref="card"
        class="service-card-container"
    >
        <div
            class="service-card"
            :style="{
                minHeight: `${minHeight}px`,
            }"
        >
            <div class="service-card__summary">
                <WatsonAtomImage
                    v-if="content.image"
                    :content="content.image"
                    class="service-card__summary-image"
                />
                <div class="service-card__summary-main">
                    <a
                        :href="content.url"
                        class="service-card__summary-title u-heading-4"
                        :title="content.title"
                    >
                        {{ content.title }}
                    </a>
                    <p
                        v-if="!hideDescription && content.description"
                        class="service-card__summary-description"
                        :title="content.description"
                    >
                        {{ content.description }}
                    </p>
                </div>
                <div
                    v-if="Object.values(content.links).length > 0"
                    class="service-card__summary-button"
                    :class="{ 'service-card__summary-button--open': isOpen }"
                    @click="toggleContent"
                >
                    <FontAwesomeIcon width="0" :icon="faChevronDown" />
                </div>
            </div>
            <div
                :class="{ 'service-card__content-wrapper--open': isOpen }"
                class="service-card__content-wrapper"
            >
                <div class="service-card__content">
                    <Link
                        v-for="link in content.links"
                        :key="link.url"
                        :href="link.url"
                        class="service-card__content-link"
                    >
                        {{ link.label }}

                        <FontAwesomeIcon :icon="faArrowRight" />
                    </Link>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import WatsonAtomImage from '@atoms/image/watson-atom-image.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronDown, faArrowRight } from '@fortawesome/pro-regular-svg-icons';

const emits = defineEmits(['updateCardSize']);
defineProps({
    content: {
        type: Object,
        required: true,
    },

    hideDescription: {
        type: Boolean,
        required: false,
        default: false,
    },

    columnIndex: {
        type: Number,
        required: false,
        default: null,
    },

    itemIndex: {
        type: Number,
        required: false,
        default: null,
    },

    minHeight: {
        type: Number,
        required: false,
        default: null,
    },
});

const card = ref(null);
const isOpen = ref(false);

const toggleContent = () => {
    isOpen.value = !isOpen.value;
};

onMounted(() => {
    setTimeout(() => {
        emits('updateCardSize', card.value.clientHeight);
    });
});
</script>

<style lang="scss">
@use 'watson-theme-service-card';
</style>
